body {
  position: static;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.admin {
  padding: 0;
  margin: 12px 8px 12px 8px;
  font-family: sans-serif;
  vertical-align: middle;
  border-collapse: collapse;
  text-align: left;
}

.admin thead tr {
  border-bottom: 2px solid #1b1054;
}

.admin tbody tr {
  padding: 3em;
  border-bottom: 1px solid rgba(15, 15, 15, 0.5);
}

.admin tr:nth-child(odd) {
  background-color: #fff;
}

.admin tr:nth-child(even) {
  background-color: rgba(27, 16, 84, 0.125);
}
