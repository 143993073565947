$body-bg: #fff;

$info: #585ed6;
$danger: tomato;
$primary: #95057d;
$secondary: #1b1054;
$success: green;
$warning: #ffbb00;
$light: #f6f3f3;
$dark: #5c5c5c;

$font-size-base: 0.95rem;

$h1-font-size: $font-size-base * 2.3;
$h2-font-size: $font-size-base * 1.9;
$h3-font-size: $font-size-base * 1.7;
$h4-font-size: $font-size-base * 1.4;
$h5-font-size: $font-size-base * 1.2;
$h6-font-size: $font-size-base;

$overflow: auto;

$theme-colors: (
  "info": $info,
  "danger": $danger,
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "warning": $warning,
  "light": $light,
  "dark": $dark,
);

@import "../node_modules/bootstrap/scss/bootstrap";
